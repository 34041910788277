import { useState } from 'react';
import emailjs from 'emailjs-com';

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          
          
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'} target="_blank" rel="noreferrer">
                    <i className="fab fa-twitter"></i>
                      <img className="icons" src="/img/icons/twitter.png" alt="React Logo" />
                       
                    </a>
                  </li>
                  <li><p>
            &copy; 2021 emOG NFT
          </p></li>
                  <li>
                    <a href={props.data ? props.data.twitter2 : '/'} target="_blank" rel="noreferrer">
                    <i className="fab fa-twitter"></i>
                      <img className="icons" src="/img/icons/twitter.png" alt="React Logo" />                      
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}
